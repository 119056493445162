import React from 'react'
// import banner from "../assets/images/carbanner.jpg";
import banner from "../../assets/images/carbanner.jpg";
import suv from "../../assets/images/cars/suv.png";
const PricePlanComponents = ({place , image , oneway ,roundtrip,}) => {
  const background={
    backgroundImage: `url(${image})`,
    backgroundSize: "contain",
    width: "100%",
    backgroundRepeat:'no-repeat',
  }
  return (
    <div className=" relative flex flex-col justify-between gap-2 py-6 px-4 rounded-lg shadow-lg border  bg-white h-full">
      <div className=' relative bg-gray-300 w-full h-56 rounded-lg' style={background}><img /></div>
      <div className=' relative w-full  bg-black rounded-tl-0 rounded-lg rounded-tl-none'>
            <div className='flex flex-col justify-center items-center gap-3 p-5  '>
              <div className='mt-4  '>
            <span className='text-xl font-semibold text-white'>Starting From</span>
            <span className="flex gap-x-1 font-bold align-baseline ml-5">
        
                  <span className=" text-xl text-white">Rs</span>
                  <span className=" text-6xl  text-white ">{oneway}</span>
              </span>
            </div>
            <div className='text-xl text-white'>Driver Betta - Rs.400</div>
            {/* <div className='text-center text-lg'>Toll, State Tax, Hills Charges, Parkings Are Extra</div> */}
            </div>
        <div className='  absolute h-20 w-3/5 -top-[80px] bg-black border-t-8 border-r-8 border-white rounded-tr-xl rounded-tl-sm' >
        <span className='relative top-5  ml-8 py-2 px-5 rounded-xl  text-3xl font-bold  bg-gray-300 ' >{place}</span>
        </div>
      </div>
    {/* <div className=' flex flex-col justify-between flex-1 h-full'>
      <p className=" font-bold">{place}</p>
      <img src={image} className=" w-[200px] mx-auto" />
      <div className=" flex gap-x-2 justify-between mt-3 pb-3  border-b flex-1">
          <div className=" flex flex-col gap-y-2 flex-1 items-center">
              <span className="flex gap-x-1 font-bold align-baseline">
                  <span className=" primary-color text-xl">Rs</span>
                  <span className=" text-3xl ">{oneway}</span>
              </span>
              <span className=" opacity-50 font-semibold">One Way</span>
          </div>
          <div className=" flex flex-col gap-y-2 flex-1 items-center">
              <span className="flex gap-x-1 font-semibold align-baseline">
                  <span className=" primary-color text-xl">Rs</span>
                  <span className=" text-3xl">{roundtrip}</span>
              </span>
              <span className=" opacity-50 font-semibold">Round Trip</span>
              
          </div>
   
      </div>
      <div className='text-center mt-2'>Toll , Permit, Parking Extra Charges *</div>
      <div className=" mt-3">
          <a  href="tel:+919944900347"  className="th-btn w-full">CHOOSE CAB</a>
      </div>
    </div> */}
  </div>
  )
}

export default PricePlanComponents
