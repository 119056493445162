import React from 'react'
import about from "../assets/about.jpg";
import { FaPhone } from 'react-icons/fa6';
const About = () => {
    return (
        <div  id='about'>
            <div className='container py-5 mx-auto px-4 md:px-8 lg:px-16'>
                <div className='block md:flex py-5 gap-x-20 items-center'>
                    <div className='w-full md:w-1/2'>
                        <div className=' relative'>
                            <img src={about} alt="about" className='mx-auto  ' />
                        </div>
                    </div>
                    <div className='mt-10 md:mt-0 px-3 w-full md:w-1/2'>
                        <h4 className=' text-lg font-medium primary-color'>About Us</h4>

                        <p className='text-black opacity-60 md:text-lg mt-5'>Welcome to Ez Taxi, where transportation meets convenience with a touch of excellence. As a premier drop taxi service, we take pride in our commitment to providing you with a seamless and reliable travel experience. Our fleet of well-maintained vehicles, coupled with professional and courteous drivers, ensures that you reach your destination safely and comfortably.
                            <br /> <br />
                            At Ez Taxi, we understand the value of your time and the importance of a stress-free journey. Whether you're heading to a business meeting, catching a flight, or simply exploring the city, we are dedicated to offering a prompt and efficient service. Our mission is to redefine your travel expectations, making every ride with Ez Taxi a journey of comfort, reliability, and satisfaction. Choose Ez Taxi for your next trip, and let us elevate your transportation experience to a whole new level.
                        </p>
                        <div className=' mt-5'>
                        <a
                href="tel:+919944900347"
                className=" rounded-md th-btn-reverse hover:bg-black transition-all ease-linear px-3 py-2"
              >
                <span className=" flex gap-x-2 items-center justify-center">
                  <FaPhone size={17} /> +91 9944900347
                </span>
              </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About