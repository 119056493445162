import React, { useEffect } from 'react'
import GoogleMapPoint from '../../GoogleMapPoint'
import Form from '../../Form'
import CarsChargeTable from '../Common/CarsChargeTable'
import PopularOutstations from '../../PopularOutstations'

const TrichytoChennai = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
<div>
<GoogleMapPoint origin={{lat:10.7905,lng:78.7047}} destination={{lat:13.0827,lng:80.2707}}/>

<div className="form_bg relative bg-fixed flex justify-center px-3">
<div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
<Form />
</div>
</div>


<div className=" container px-3 sm:px-4 md:px-5 lg:px-10 text-[#082a5e] py-4">
<h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase">
TRICHY TO Chennai TAXI SERVICE: COMFORTABLE AND RELIABLE TRANSPORTATION
</h3>
<p className=" pt-2">
Embarking on a journey from Trichy to Chennai and in need of a comfortable and reliable taxi service? Look no further! Our taxi service offers seamless transportation that ensures your trip is smooth, convenient, and enjoyable.

</p>

<h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase mt-5">
DOORSTEP PICKUP AND DROP-OFF:
</h3>
<p className=" pt-2">
We understand the importance of punctuality. Our drivers will arrive promptly at your specified location in Trichy and ensure you reach Chennai on time, without any unnecessary delays.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
COMFORTABLE AND WELL-MAINTAINED VEHICLES:
</h3>
<p className=" pt-2">
Choose from a range of comfortable vehicles, including sedans, SUVs, and spacious options suitable for groups. Our fleet is well-maintained to provide a comfortable ride throughout your journey.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
PROFESSIONAL AND RELIABLE DRIVERS:
</h3>
<p className=" pt-2">
Our drivers are experienced, professional, and familiar with the best routes between Trichy and Chennai. They prioritize your safety and ensure a pleasant travel experience.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
TRANSPARENT PRICING:
</h3>
<p className=" pt-2">
Our pricing is transparent and competitive, with no hidden charges. You'll receive a fair and upfront fare estimate before booking your ride, allowing you to plan your travel budget effectively.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
CONVENIENT BOOKING:
</h3>
<p className=" pt-2">
Whether you need a taxi during the day or night, our services operate 24/7 to accommodate your travel needs. You can rely on us for a comfortable and reliable ride at any time.


</p>

<div className=" my-4 sm:my-6 md:my-10">
<CarsChargeTable />
</div>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
24/7 Customer Support:
</h3>
<p className=" pt-2">
Whether you need a taxi during the day or night, our services operate 24/7 to accommodate your travel needs. You can rely on us for a comfortable and reliable ride at any time.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
THE ENCHANTING JOURNEY FROM TRICHY TO Chennai:
</h3>
<p className=" pt-2">
Make your journey from Trichy to Chennai stress-free and enjoyable with our reliable taxi service. Sit back, relax, and enjoy the scenic landscapes as we take you to your destination safely and comfortably.

</p>

<p className=" pt-5">
Contact us today to book your taxi and experience convenient travel with exceptional service. We look forward to serving you and ensuring your trip to Chennai is memorable for all the right reasons.

</p>
</div>
< PopularOutstations />

    </div>  )
}

export default TrichytoChennai
