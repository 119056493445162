import React from "react";
import img1 from "../../assets/images/services/onewayser1.gif";
import img2 from "../../assets/images/services/roundtripser2.gif";
import img3 from "../../assets/images/services/aeroser3.gif";
import img4 from "../../assets/images/services/interser3.gif";
import img5 from "../../assets/images/services/ser5.png";
const OurServices = () => {
  return (
    <div id='services'>
      <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
          Our Service
        </h4>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-8">
          <div className="flex flex-col primary-bgcolor opacity-90 border-b-[20px] border-white opacity-65 gap-5 text-center text-black px-4 py-12 rounded-lg shadow-xl align-baseline">
            <div > <img src={img1} className="h-[229px] w-[424px] rounded-2xl"/> </div>
       
               <span className="text-center text-3xl font-bold text-black">ONE WAY TAXI</span>
            <span>You can't buy a round-trip ticket! Why overpay for the least dependable taxi service in all of Tamil Nadu when you may take use of the best there is?</span>
          </div>
          <div className="flex flex-col primary-bgcolor opacity-90 border-b-[20px] border-white opacity-65 gap-5 text-center text-black px-4 py-12 rounded-lg shadow-xl align-baseline">
            <div> <img src={img2} className="h-[229px] w-[424px] rounded-2xl"/> </div>
       
               <span className="text-center text-3xl font-bold text-black">Round Trip Taxi</span>
            <span>Your pick-up location can be anywhere in the city you're arriving in, and your drop-off location can be anywhere in the city you're leaving.</span>
          </div>
          <div className="flex flex-col primary-bgcolor opacity-90 border-b-[20px] border-white opacity-65 gap-5 text-center text-black px-4 py-12 rounded-lg shadow-xl align-baseline">
            <div> <img src={img3} className="h-[229px] w-[424px] rounded-2xl"/> </div>
       
               <span className="text-center text-3xl font-bold text-black">Airport Taxi</span>
            <span>Being ready for the unexpected is crucial, whether it's to meet a buddy at the airport on time or to quickly leave a dangerous situation.</span>
          </div>
          <div className="flex flex-col primary-bgcolor opacity-90 border-b-[20px] border-white opacity-65 gap-5 text-center text-black px-4 py-12 rounded-lg shadow-xl align-baseline">
            <div> <img src={img4} className="h-[229px] w-[424px] rounded-2xl"/> </div>
       
               <span className="text-center text-3xl font-bold text-black">Intercity Taxi</span>
            <span>Safe and reliable services provided by Ez Taxi are available between many different cities in India.</span>
          </div>
          <div className="flex flex-col primary-bgcolor opacity-90 border-b-[20px] border-white opacity-65 gap-5 text-center text-black px-4 py-12 rounded-lg shadow-xl align-baseline">
            <div> <img src={img5} className="h-[229px] w-[424px] rounded-2xl"/> </div>
       
               <span className="text-center text-3xl font-bold text-black">Outstation Taxi</span>
            <span>Our Outstation taxis are the most flexible for trips of any length, making them perfect for bringing the whole family together.</span>
          </div>
        </div>
        {/* <h3 className="mt-4 font-semibold text-3xl">
          "EZ Taxi: The Best Choice for Drop Taxi Services in South India"
        </h3> */}
        {/* <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <p>
            <b className="pr-2">Drop Taxi Service Across Tamil Nadu :</b>
            We cover all major cities, including Chennai, Coimbatore, Madurai, Trichy, and more.
          </p>
          <p>
            <b className="pr-2">Pondicherry Drop Service : </b>
            Enjoy a seamless trip to the beautiful coastal town of Pondicherry.          </p>
            <p>
            <b className="pr-2">Bangalore Drop Taxi :  </b>
            Travel hassle-free from Tamil Nadu to Bangalore, with flexible timing and safe driving.                </p>
        </div> */}
      </div>
      {/* <div className="  px-0 sm:px-4 md:px-8 lg:px-16 bg-[#f5f5f5]  py-3 md:py-5 mt-4 md:mt-6 lg:mt-8">
        <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2  text-center">
          <div className=" flex flex-col gap-y-1">
            <img src={img1} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Easy Online Booking
            </p>
            <p>Pickup and Drop on time</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img2} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
            Professional Drivers
            </p>
            <p>Well Trained years of experience</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img3} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
            Variety of Prime Brands
            </p>
            <p>Benz - BMW - Audi</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img4} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
            Secure Online Payment
            </p>
            <p>24 /7 Customer Support</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default OurServices;
