import React from 'react'
import banner from "../../assets/images/logo.png";
import { MdSafetyCheck } from "react-icons/md";
import { MdOutlineSupportAgent } from "react-icons/md";
import { MdStarRate } from "react-icons/md";
import { RiServiceFill } from "react-icons/ri";
const ChooseUs = () => {
    const divStyle = {
        backgroundImage: `url(${banner})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        width: "100%",
    };
    return (
        <div className='form_bg1 relative bg-fixed ' style={divStyle}>
            <div className="container py-10 md:py-20 lg:py-28 mx-auto px-0 sm:px-4 md:px-8 lg:px-16 relative z-10">
                 <div class='text-center text-2xl font-bold pb-8 text-black'>Why Choose Us ?</div>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2'>
                    <div className=' flex flex-col justify-center gap-2 border-2 border-slate-950 shadow-xl rounded-xl p-4 backdrop-blur-sm'>
                        <div className='flex justify-center'>
                            <MdSafetyCheck size={60} className='text-black ' />
                        </div>
                        <div className='font-bold text-xl text-center text-black'>Convenient Booking: </div>
                        <p className='text-center text-black' >Book your ride through our user-friendly platform with just a few clicks or a phone call.</p>
                    </div>
                    <div className=' flex flex-col justify-center gap-2 border-2 border-slate-950 shadow-xl rounded-xl p-4 backdrop-blur-sm'>
                        <div className='flex justify-center'>
                            <MdOutlineSupportAgent size={60} className='text-black ' />
                            
                        </div>
                        <div className='font-bold text-xl text-center text-black'>24/7 Availability : </div>
                        <p className='text-center text-black'>
                        No matter the time of day, we are ready to serve you. Book a ride anytime, and we'll be there to assist you.</p>

                    </div>
                    <div className=' flex flex-col justify-center gap-2 border-2 border-slate-950 shadow-xl rounded-xl p-4 backdrop-blur-sm'>
                        <div className='flex justify-center'>
                            <MdStarRate size={60} className='text-black ' />
                        </div>
                        <div className='font-bold text-xl text-center text-black'> Well-Maintained Fleet :</div>
                        <p className='text-center text-black'>
                        From sedans to SUVs, we have a wide range of vehicles to suit your travel needs, ensuring comfort and reliability. 
                                                                      </p>
                    </div>
                    <div className=' flex flex-col justify-center gap-2 border-2 border-slate-950 shadow-xl rounded-xl p-4 backdrop-blur-sm'>
                        <div className='flex justify-center'>
                            <RiServiceFill size={60} className='text-black ' />
                        </div>
                        <div className='font-bold text-xl text-center text-black'>No Return Fare : </div>
                        <p className='text-center text-black    '>
                        Pay only for the distance you travel. No hidden charges or extra fees for the return trip.                                                                      </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChooseUs